import { Product } from "./../interfaces/product.interface";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  private API = environment.API + "products/";
  constructor(private http: HttpClient) {}

  editPrice(product: Product, price: number): Observable<string> {
    return this.http.put(this.API + "price", { _id: product._id, price }).pipe(pluck("message"));
  }

  editCorrelative(number: number): Observable<string> {
    return this.http.post(this.API + "correlative", { number }).pipe(pluck("message"));
  }

  getCorrelative() {
    return this.http.get<Number>(this.API + "correlative");
  }

  reschedule(product: Product, deliveryDate: Date): Observable<string> {
    return this.http.post(this.API + "reschedule", { _id: product._id, deliveryDate }).pipe(pluck("message"));
  }

  setCompleted(product: Partial<Product>): Observable<string> {
    return this.http.put(this.API + "completed", { _id: product._id, completed: true }).pipe(pluck("message"));
  }
}
