import { RouterModule } from "@angular/router";
import { MaterialModule } from "./../material/material.module";
import { LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ImageCropperModule } from "ngx-image-cropper";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
registerLocaleData(localeEs, "es");
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { QuicklinkModule } from "ngx-quicklink";

import { HeaderComponent } from "./components/header/header.component";
import { NavComponent } from "./components/nav/nav.component";
import { AlertComponent } from "./components/alert/alert.component";
import { SeleccionarImagenComponent } from "./modal/seleccionar-imagen/seleccionar-imagen.component";
import { CalificarServicioComponent } from "./modal/calificar-servicio/calificar-servicio.component";
import { CalendarioComponent } from "./components/calendario/calendario.component";
import { NotificacionesComponent } from "./components/notificaciones/notificaciones.component";
import { ConfiguracionesComponent } from "./components/configuraciones/configuraciones.component";
import { ConfirmarComponent } from "./modal/confirmar/confirmar.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { TableComponent } from "./components/table/table.component";
import { SocketIoModule } from "ngx-socket-io";
import { InputGooglePlaceComponent } from "./components/input-google-place/input-google-place.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
import { CaptureComponent } from "./components/capture/capture.component";
import { EditPriceComponent } from "./components/edit-price/edit-price.component";
import { EditCorrelativeComponent } from "./components/edit-correlative/edit-correlative.component";
import { DateRangeInputComponent } from "./components/date-range-input/date-range-input.component";
import { DialogContentRadioButtonsComponent } from "./components/dialog-content-radio-buttons/dialog-content-radio-buttons.component";

@NgModule({
  declarations: [
    HeaderComponent,
    NavComponent,
    AlertComponent,
    SeleccionarImagenComponent,
    CalificarServicioComponent,
    CalendarioComponent,
    NotificacionesComponent,
    ConfiguracionesComponent,
    ConfirmarComponent,
    LoaderComponent,
    TableComponent,
    InputGooglePlaceComponent,
    CaptureComponent,
    EditPriceComponent,
    EditCorrelativeComponent,
    DateRangeInputComponent,
    DialogContentRadioButtonsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ImageCropperModule,
    ReactiveFormsModule,
    FormsModule,
    QuicklinkModule,
    GooglePlaceModule,
  ],
  exports: [
    HeaderComponent,
    NavComponent,
    AlertComponent,
    SeleccionarImagenComponent,
    CalificarServicioComponent,
    CalendarioComponent,
    NotificacionesComponent,
    ConfiguracionesComponent,
    LoaderComponent,
    TableComponent,
    QuicklinkModule,
    ConfirmarComponent,
    SocketIoModule,
    InputGooglePlaceComponent,
    CaptureComponent,
    DateRangeInputComponent,
    DialogContentRadioButtonsComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    DatePipe,
  ],
})
export class SharedModule {}
