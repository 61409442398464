// @ts-ignore
import { Column } from "exceljs/dist/exceljs.min.js";
import { style } from "./styles.constant";

export const HeaderDelivery: Column[] = [
  { header: "", key: "fieldEmpty", width: 20, style },
  { header: "FECHA", key: "retirementDate", width: 30, style },
  { header: "CÓDIGO INTERNO", key: "internalCode", width: 30, style },
  { header: "Nª", key: "number", width: 30, style },
  { header: "NOMBRE DE LA PYME", key: "storeName", width: 45, style },
  { header: "NOMBRE", key: "deliveryName", width: 45, style },
  { header: "TELÉFONO", key: "deliveryPhone", width: 35, style },
  { header: "CORREO ELECTRÓNICO", key: "deliveryEmail", width: 55, style },
  { header: "DIRECCIÓN", key: "deliveryAddress", width: 75, style },
  { header: "NÚMERO DE DEPTO/CASA Y COMENTARIO EN CASO DE HABER", key: "details", width: 75, style },
  { header: "COMUNA", key: "deliveryCommuna", width: 55, style },
  { header: "ENTREGADO", key: "delivered", width: 35, style },
  { header: "COMENTARIO", key: "comment", width: 35, style },
  { header: "VALORES", key: "values", width: 35, style },
  { header: "PAGO CONDUCTOR", key: "driverPayment", width: 35, style },
  { header: "PATENTE", key: "patent", width: 35, style },
  { header: "CHOFER", key: "driver", width: 35, style },
];
