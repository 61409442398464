import { Columns } from "src/app/shared/interfaces/columns.interface";

export const PreviewRetirementColumns: Columns = {
  columns: [
    ["BULTOS", "numberOfPackages"],
    ["NOMBRE DE TU PYME", "storeName"],
    ["MAIL DE CONTACTO", "email"],
    ["NOMBRE DE QUIÉN ENTREGA", "name"],
    ["TELÉFONO", "phone"],
    ["DIRECCIÓN DE RETIRO", "withdrawalAddress"],
    ["COMUNA", "commune"],
    ["NÚMERO DE DEPTO/CASA O COMENTARIO EN CASO DE HABER", "address"],
    ["HORA DE AGENDAMIENTO DEL RETIRO", "creationTime"],
    ["", "opciones"],
  ],
  columnsSelect: [
    "numberOfPackages",
    "storeName",
    "email",
    "name",
    "phone",
    "withdrawalAddress",
    "commune",
    "address",
    "creationTime",
    "opciones",
  ],
  displayedColumns: [
    "numberOfPackages",
    "storeName",
    "email",
    "name",
    "phone",
    "withdrawalAddress",
    "commune",
    "address",
    "creationTime",
    "opciones",
  ],
  480: ["storeName", "commune", "creationTime"],
  680: ["storeName", "name", "phone", "commune", "creationTime"],
  768: ["storeName", "name", "phone", "commune", "creationTime"],
  1024: ["numberOfPackages", "storeName", "name", "phone", "commune", "creationTime"],
};
