import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-content-radio-buttons",
  templateUrl: "./dialog-content-radio-buttons.component.html",
  styleUrls: ["../../../shared/css/modal.css", "./dialog-content-radio-buttons.component.css"],
})
export class DialogContentRadioButtonsComponent implements OnInit {
  @Input() title = "";
  value = this.data.active;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; active: any; options: { value: any; label: string }[] }
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close();
  }
  accepted() {
    this.dialogRef.close(this.value);
  }
}
