// @ts-ignore
import { Column } from "exceljs/dist/exceljs.min.js";
import { style } from "./styles.constant";

export const HeaderUserDelivery: Column[] = [
  { header: "", key: "fieldEmpty", width: 20, style },
  { header: "FECHA", key: "retirementDate", width: 30, style },
  { header: "CÓDIGO INTERNO", key: "internalCode", width: 30, style },
  { header: "Nª", key: "number", width: 30, style },
  { header: "NOMBRE DE LA PYME", key: "storeName", width: 45, style },
  { header: "NOMBRE", key: "deliveryName", width: 45, style },
  { header: "TELÉFONO", key: "deliveryPhone", width: 35, style },
  { header: "CORREO ELECTRÓNICO", key: "deliveryEmail", width: 55, style },
  { header: "DIRECCIÓN", key: "deliveryAddress", width: 75, style },
  { header: "NÚMERO DE DEPTO/CASA Y COMENTARIO EN CASO DE HABER", key: "details", width: 75, style },
  { header: "COMUNA", key: "deliveryCommuna", width: 55, style },
  { header: "VALUACIÓN DEL PAQUETE", key: "packageValuation", width: 35, style },
  { header: "VALOR NETO", key: "price", width: 35, style },
  { header: "FACTURA", key: "withIVA", width: 35, style },
];
