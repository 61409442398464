import { MatDialog } from "@angular/material/dialog";
import { HeaderConsolidated } from "./../constants/header-consolidado.constant";
import { Report } from "./../interfaces/report.interface";
import { Dispatch } from "./../../despachos/shared/interfaces/dispatch.interface";
import { Injectable } from "@angular/core";
import { ExcelService } from "./excel.service";
import { WorksheetModel } from "../models/worksheet.model";
import { DialogTableConsolidateComponent } from "../preview/consolidate/dialog-table.component";

@Injectable({
  providedIn: "root",
})
export class ExcelConsolidatedService extends ExcelService implements Report<Dispatch[]> {
  constructor(private dialog: MatDialog) {
    super("Plantilla de consolidado");
  }

  //@ts-ignore
  generate(dispatches: Dispatch[]) {
    super.generateOnePage(new WorksheetModel<any>(this.name, HeaderConsolidated, this.parsear(dispatches)));
  }

  preview(dispatches: Dispatch[]): void {
    this.dialog.open(DialogTableConsolidateComponent, {
      width: "90vw",
      data: this.parsear(dispatches),
    });
  }

  private parsear(dispatches: Dispatch[]) {
    return dispatches.map((dispatch) => ({
      numberOfPackages: dispatch.products.length,
      retirementDate: dispatch.retirementDate,
      storeName: dispatch.user.storeName?.toUpperCase(),
      amount: dispatch.amount,
      iva: dispatch.iva || 0,
      amountWithIVA: dispatch.amountWithIVA || 0,
    }));
  }
}
