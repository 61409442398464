import { Columns } from "src/app/shared/interfaces/columns.interface";

export const PreviewConsolidateColumns: Columns = {
  columns: [
    ["CANTIDAD DE BULTOS", "numberOfPackages"],
    ["FECHA DE ENVÍO", "retirementDate"],
    ["NOMBRE DE TU PYME", "storeName"],
    ["TOTAL NETO", "amount"],
    ["IVA", "iva"],
    ["TOTAL BRUTO", "amountWithIVA"],
  ],
  columnsSelect: ["numberOfPackages", "retirementDate", "storeName", "amount", "iva", "amountWithIVA"],
  displayedColumns: ["numberOfPackages", "retirementDate", "storeName", "amount", "iva", "amountWithIVA"],
  480: ["storeName", "retirementDate"],
  680: ["numberOfPackages", "storeName", "retirementDate"],
  768: ["numberOfPackages", "storeName", "retirementDate", "amount", "iva", "amountWithIVA"],
  1024: ["numberOfPackages", "storeName", "retirementDate", "amount", "iva", "amountWithIVA"],
};
