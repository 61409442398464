import { AlertService } from "src/app/shared/services/alert.service";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmarComponent } from "src/app/shared/modal/confirmar/confirmar.component";
import { PreviewDeliveryColumns } from "../../constants/preview-delivery-columns.constant";
import { Product } from "./../../../despachos/shared/interfaces/product.interface";
import { ProductsService } from "./../../../despachos/shared/services/products.service";

@Component({
  selector: "app-dialog-table",
  templateUrl: "./dialog-table.component.html",
  styleUrls: ["../../../shared/css/modal.css", "./dialog-table.component.css"],
})
export class DialogTableDeliveryComponent {
  columns = PreviewDeliveryColumns;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialogRef: MatDialogRef<any>,
    private productService: ProductsService,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  setCompleted(product: Product) {
    const dialogRef = this.dialog.open(ConfirmarComponent, {
      data: {
        title: "Establecer como completado",
        description: `¿Desea establecer el envío como completado?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productService.setCompleted(product).subscribe((message) => {
          this.success(message);
          this.data = this.data.filter((current) => current._id !== product._id);
        }, this.error);
      }
    });
  }

  private success = (message: string) => {
    this.alert.setAlert({ mensaje: message, tipo: "success" });
  };

  private error = (message: string) => {
    this.alert.setAlert({ mensaje: message, tipo: "error" });
  };
}
