import { WorksheetModel } from "../models/worksheet.model";
import { DateCustom } from "./../../shared/services/custom-date.service";
// @ts-ignore
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { Dispatch } from "src/app/despachos/shared/interfaces/dispatch.interface";
// @ts-ignore
import { Worksheet } from "exceljs";

export abstract class ExcelService {
  private TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  private workbook = new Workbook();
  private worksheet: Worksheet;

  constructor(protected name: string) {}

  private build(sheet: WorksheetModel<Partial<Dispatch[]>>) {
    this.worksheet = this.workbook.addWorksheet(sheet.name, { views: [{ showGridLines: false }] });
    this.worksheet.columns = sheet.header;
    sheet.data.forEach((dispatch) => this.worksheet.addRow(dispatch));
    this.setHeaderStyle();
    this.setRowStyle();
  }

  private resetBook() {
    this.workbook = new Workbook();
  }

  async generateOnePage(sheet: WorksheetModel<Partial<Dispatch[]>>) {
    this.build(sheet);
    await this.save();
    this.resetBook();
  }

  async generateManyPage(sheets: WorksheetModel<Partial<Dispatch[]>>[]) {
    sheets.forEach((sheet) => this.build(sheet));
    await this.save();
    this.resetBook();
  }

  private setHeaderStyle() {
    this.worksheet.getRow(1).height = 42;
    this.worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell: any) => {
      if (cell.address !== "A1") {
        this.worksheet.getCell(cell.address).fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFF9D12" },
          bgColor: { argb: "FFF9D12" },
        };
      }
    });
  }

  private setRowStyle() {
    this.worksheet._rows.forEach((row: any) => {
      row.eachCell({ includeEmpty: true }, (cell: any) => {
        if (!cell._address.includes("A")) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
    });
  }

  private async save() {
    const data = await this.workbook.xlsx.writeBuffer();
    const blob = new Blob([data], { type: this.TYPE });
    saveAs(blob, this.name + "-" + DateCustom.shortDate(new Date()) + ".xlsx");
  }
}
