import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-calendario",
  templateUrl: "./calendario.component.html",
  styleUrls: ["./calendario.component.css"],
})
export class CalendarioComponent implements OnInit {
  week: any = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

  monthSelect: any[];
  dateSelect: any;
  dateActual: any = new Date();

  @Output() dateValue = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.getDaysFromDate(this.dateActual.getMonth() + 1, this.dateActual.getFullYear());
  }

  getDaysFromDate(month: any, year: any) {
    const startDate = moment.utc(`${year}/${month}/01`);
    const endDate = startDate.clone().endOf("month");
    this.dateSelect = endDate;

    const diffDays = endDate.diff(startDate, "days", true);
    const numberDays = Math.round(diffDays);

    const arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${year}-${month}-${a}`);
      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday(),
      };
    });

    this.monthSelect = arrayDays;
  }

  changeMonth(flag: any) {
    if (flag < 0) {
      const prevDate = this.dateSelect.clone().subtract(1, "month");
      this.getDaysFromDate(prevDate.format("MM"), prevDate.format("YYYY"));
    } else {
      const nextDate = this.dateSelect.clone().add(1, "month");
      this.getDaysFromDate(nextDate.format("MM"), nextDate.format("YYYY"));
    }
  }

  clickDay(day: any) {
    const monthYear = this.dateSelect.format("YYYY-MM");
    const parse = `${monthYear}-${day.value}`;
    const date = new Date((<any>moment(parse))["_i"]);
    date.setHours(23, 0, 0);
    this.dateValue.emit(date);
  }
}
