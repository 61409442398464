import { Columns } from "src/app/shared/interfaces/columns.interface";

export const PreviewUserDeliveryColumns: Columns = {
  columns: [
    ["Fecha", "retirementDate"],
    ["Código interno", "internalCode"],
    ["N°", "number"],
    ["NOMBRE DE TU PYME", "storeName"],
    ["NOMBRE", "deliveryName"],
    ["TELÉFONO", "deliveryPhone"],
    ["CORREO ELECTRÓNICO", "deliveryEmail"],
    ["DIRECCIÓN", "deliveryAddress"],
    ["NÚMERO DE DEPTO/CASA Y COMENTARIO EN CASO DE HABER", "details"],
    ["Comuna", "deliveryCommuna"],
    ["VALUACIÓN DEL PAQUETE", "packageValuation"],
    ["VALOT NETO", "price"],
    ["FACTURA", "withIVA"],
  ],
  columnsSelect: [
    "retirementDate",
    "internalCode",
    "number",
    "storeName",
    "deliveryName",
    "deliveryPhone",
    "deliveryEmail",
    "deliveryAddress",
    "details",
    "address",
    "deliveryCommuna",
    "packageValuation",
    "price",
    "withIVA",
  ],
  displayedColumns: [
    "retirementDate",
    "internalCode",
    "number",
    "storeName",
    "deliveryName",
    "deliveryPhone",
    "deliveryEmail",
    "deliveryAddress",
    "details",
    "address",
    "deliveryCommuna",
    "packageValuation",
    "price",
    "withIVA",
  ],
  480: ["retirementDate", "storeName", "details", "deliveryCommuna"],
  680: ["retirementDate", "storeName", "deliveryPhone", "deliveryEmail", "details", "deliveryCommuna"],
  768: ["retirementDate", "storeName", "deliveryPhone", "deliveryEmail", "details", "deliveryCommuna"],
  1024: ["retirementDate", "number", "storeName", "deliveryCommuna", "price"],
};
