import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./../material/material.module";
import { DialogTableRetirementComponent } from "./preview/retirement/dialog-table.component";
import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { DialogTableConsolidateComponent } from "./preview/consolidate/dialog-table.component";
import { DialogTableDeliveryComponent } from "./preview/delivery/dialog-table.component";
import { DialogUserTableDeliveryComponent } from "./preview/deliveryUser/dialog-user-table.component";

@NgModule({
  declarations: [
    DialogTableRetirementComponent,
    DialogTableDeliveryComponent,
    DialogTableConsolidateComponent,
    DialogUserTableDeliveryComponent,
  ],
  imports: [CommonModule, SharedModule, MaterialModule, ReactiveFormsModule],
})
export class ReportsModule {}
