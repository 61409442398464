import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-date-range-input",
  templateUrl: "./date-range-input.component.html",
  styleUrls: ["./date-range-input.component.css"],
})
export class DateRangeInputComponent {
  range = new FormGroup({
    start: new FormControl("", [Validators.required]),
    end: new FormControl("", [Validators.required]),
  });

  @Input() template: TemplateRef<any>;
  @Output() onRange = new EventEmitter<{ start: Date; end: Date }>();

  emit() {
    if (this.range.valid) {
      const range: { start: Date; end: Date } = this.range.value;
      const startDate = new Date(range.start);
      const endDate = new Date(range.end);
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(23, 59, 0, 0);
      this.onRange.emit({
        start: startDate.toUTCString() as any,
        end: endDate.toUTCString() as any,
      });
    }
  }
}
