import { MatDialog } from "@angular/material/dialog";
import { HeaderDelivery } from "./../constants/header-delivery.constant";
import { Report } from "./../interfaces/report.interface";
import { Dispatch } from "./../../despachos/shared/interfaces/dispatch.interface";
import { Injectable } from "@angular/core";
import { ExcelService } from "./excel.service";
import { WorksheetModel } from "../models/worksheet.model";
import { DialogTableDeliveryComponent } from "../preview/delivery/dialog-table.component";
import { ZoneService } from "src/app/zone/services/zone.service";

@Injectable({
  providedIn: "root",
})
export class ExcelDeliverService extends ExcelService implements Report<Dispatch[]> {
  constructor(private dialog: MatDialog, private zoneService: ZoneService) {
    super("Plantilla de envío");
  }

  generate(dispatches: Dispatch[]) {
    this.zoneService.getAll().subscribe((zones) => {
      const products = this.parsear(dispatches);
      const data: any[] = [];
      zones.forEach((zone) => {
        console.log(zone);
        const _dispatches = products.filter((product) => product.zoneName === zone.name);
        data.push(new WorksheetModel<any>(zone.name, HeaderDelivery, _dispatches));
      });
      super.generateManyPage(data);
    });
  }

  preview(dispatches: Dispatch[]): void {
    this.dialog.open(DialogTableDeliveryComponent, {
      width: "90vw",
      data: this.parsear(dispatches),
    });
  }

  private parsear(dispatches: Dispatch[]) {
    const result = [];
    for (const dispatch of dispatches) {
      result.push(
        ...dispatch.products.map((product) => ({
          fieldEmpty: "",
          retirementDate: dispatch.retirementDate,
          internalCode: product.internalCode,
          number: product.number,
          storeName: dispatch.user.storeName?.toUpperCase(),
          deliveryName: product.deliveryName?.toUpperCase(),
          deliveryPhone: product.deliveryPhone?.toUpperCase(),
          deliveryEmail: product.deliveryEmail?.toUpperCase(),
          deliveryAddress: product.deliveryAddress?.toUpperCase(),
          details: product.details?.toUpperCase(),
          deliveryCommuna: product.deliveryCommuna?.name?.toUpperCase(),
          delivered: "",
          comment: "",
          values: "",
          driverPayment: "",
          patent: "",
          driver: "",
          _id: product._id,
          zoneName: product.deliveryCommuna?.zone?.name,
        }))
      );
    }
    return result;
  }
}
