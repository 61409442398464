import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ModifyPasswordComponent } from "./modify-password/modify-password.component";
import { RecoverPasswordComponent } from "./recover-password/recover-password.component";
import { LoginGuard } from "./shared/guards/login.guard";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "nueva-credencial/:token",
        component: ModifyPasswordComponent,
      },
      {
        path: "recuperar-contraseña",
        component: RecoverPasswordComponent,
      },
      {
        path: "login",
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
