import { AlertService } from "src/app/shared/services/alert.service";
import { ProductsService } from "./../../../despachos/shared/services/products.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-edit-correlative",
  templateUrl: "./edit-correlative.component.html",
  styleUrls: ["../../../shared/css/modal.css", "../../../shared/css/form.css", "./edit-correlative.component.css"],
})
export class EditCorrelativeComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private productsService: ProductsService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.productsService.getCorrelative().subscribe((correlative) => {
        this.form = this.fb.group({
          number: [correlative, [Validators.required, Validators.min(Number(correlative))]],
        });
      }, this.error);
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      let { number } = this.form.value;
      this.productsService.editCorrelative(number).subscribe(this.success, this.error);
    }
  }

  verificarInput(value: string) {
    return this.form.get(value)?.invalid && (this.form.get(value)?.dirty || this.form.get(value)?.touched);
  }

  private success = (message: string) => {
    this.alert.setAlert({ mensaje: message, tipo: "success" });
    this.dialogRef.close();
  };

  private error = (message: string) => {
    this.alert.setAlert({ mensaje: message, tipo: "error" });
  };
}
