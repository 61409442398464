import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Report, ReportOperation } from "./../interfaces/report.interface";
import { ReportTypes } from "./../enums/report-types";
import { Dispatch } from "src/app/despachos/shared/interfaces/dispatch.interface";
import { ExcelRetirementService } from "./excel-retirement.service";
import { ExcelDeliverService } from "./excel-deliver.service";
import { ExcelConsolidatedService } from "./excel-consolidated.service";
import { DialogContentRadioButtonsComponent } from "src/app/shared/components/dialog-content-radio-buttons/dialog-content-radio-buttons.component";
import { ActionViewReport } from "../enums/actions-view-report.enum";
import { ExcelUserDeliverService } from "./excel-user-delivery.service";

@Injectable({
  providedIn: "root",
})
export class ReportFactoryService {
  private types: Map<ReportTypes, Report<Dispatch[]>> = new Map();
  private report: Report<Dispatch[]>;

  constructor(
    private dialog: MatDialog,
    excelRetirementService: ExcelRetirementService,
    excelConsolidatedService: ExcelConsolidatedService,
    excelDeliverService: ExcelDeliverService,
    excelUserDeliverService: ExcelUserDeliverService
  ) {
    this.types.set(ReportTypes.RETIREMENT, excelRetirementService);
    this.types.set(ReportTypes.DELIVER, excelDeliverService);
    this.types.set(ReportTypes.CONSOLIDATED, excelConsolidatedService);
    this.types.set(ReportTypes.USER_DELIVERY, excelUserDeliverService);
  }

  setType(type: ReportTypes) {
    this.report = this.types.get(type)!;
    return this;
  }

  generate(dispatches: Dispatch[]) {
    this.dialog
      .open(DialogContentRadioButtonsComponent, {
        data: {
          title: "¿Que deseas realizar?",
          active: "generate",
          options: [
            { label: ActionViewReport.DOWNLOAD, value: "generate" },
            { label: ActionViewReport.PREVIEW, value: "preview" },
          ],
        },
      })
      .afterClosed()
      .subscribe((result: ReportOperation) => {
        if (result) {
          this.report[result](dispatches);
        }
      });
  }
}
