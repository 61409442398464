import { MatDialog } from "@angular/material/dialog";
import { Report } from "./../interfaces/report.interface";
import { Dispatch } from "./../../despachos/shared/interfaces/dispatch.interface";
import { Injectable } from "@angular/core";
import { ExcelService } from "./excel.service";
import { WorksheetModel } from "../models/worksheet.model";
import { DialogUserTableDeliveryComponent } from "../preview/deliveryUser/dialog-user-table.component";
import { ZoneService } from "src/app/zone/services/zone.service";
import { HeaderUserDelivery } from "../constants/header-user-delivery.constant";

@Injectable({
  providedIn: "root",
})
export class ExcelUserDeliverService extends ExcelService implements Report<Dispatch[]> {
  constructor(private dialog: MatDialog, private zoneService: ZoneService) {
    super("Plantilla de envío");
  }

  generate(dispatches: Dispatch[]) {
    const auxProducts: any[] = [];
    let auxIndex = 0;
    dispatches.map((dispatch) => {
      const productTotalPrice = dispatch.products?.reduce((acc, product) => acc + product.price, 0);
      auxIndex += dispatch.products.length;
      if (productTotalPrice < dispatch.amount) {
        auxProducts.push({
          product: {
            fieldEmpty: "",
            retirementDate: dispatch.retirementDate,
            internalCode: "",
            number: "",
            storeName: dispatch.user.storeName?.toUpperCase(),
            deliveryName: "Recargo por concepto 'retiro'",
            deliveryPhone: "",
            deliveryEmail: "",
            deliveryAddress: "",
            details: "",
            deliveryCommuna: "",
            packageValuation: "",
            price: dispatch.amount - productTotalPrice,
            withIVA: !!dispatch.amountWithIVA ? "SI" : "NO",
            zoneName: dispatch.products[0].deliveryCommuna?.zone?.name,
          },
          index: auxIndex,
        });
        auxIndex += 1;
      }
    });
    this.zoneService.getAll().subscribe((zones) => {
      console.log(auxProducts);
      const products = this.parsear(dispatches);
      auxProducts.forEach((aux) => products.splice(aux.index, 0, aux.product));
      const data: any[] = [];
      zones.forEach((zone) => {
        const _dispatches = products.filter((product) => {
          product.zoneName ??= "GLOBAL";
          return product.zoneName === zone.name;
        });
        data.push(new WorksheetModel<any>(zone.name, HeaderUserDelivery, _dispatches));
      });
      super.generateManyPage(data);
    });
  }

  preview(dispatches: Dispatch[]): void {
    this.dialog.open(DialogUserTableDeliveryComponent, {
      width: "90vw",
      data: this.parsear(dispatches),
    });
  }

  private parsear(dispatches: Dispatch[]) {
    const result = [];
    for (const dispatch of dispatches) {
      result.push(
        ...dispatch.products.map((product) => ({
          fieldEmpty: "",
          retirementDate: dispatch.retirementDate,
          internalCode: product.internalCode,
          number: product.number,
          storeName: dispatch.user.storeName?.toUpperCase(),
          deliveryName: product.deliveryName?.toUpperCase(),
          deliveryPhone: product.deliveryPhone?.toUpperCase(),
          deliveryEmail: product.deliveryEmail?.toUpperCase(),
          deliveryAddress: product.deliveryAddress?.toUpperCase(),
          details: product.details?.toUpperCase(),
          deliveryCommuna: product.deliveryCommuna?.name?.toUpperCase(),
          packageValuation: product.packageValuation,
          price: product.price,
          withIVA: !!dispatch.amountWithIVA ? "SI" : "NO",
          zoneName: product.deliveryCommuna?.zone?.name,
        }))
      );
    }
    return result;
  }
}
