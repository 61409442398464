import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreviewConsolidateColumns } from "../../constants/preview-consolidate-columns.constant";

@Component({
  selector: "app-dialog-table",
  templateUrl: "./dialog-table.component.html",
  styleUrls: ["../../../shared/css/modal.css", "./dialog-table.component.css"],
})
export class DialogTableConsolidateComponent {
  columns = PreviewConsolidateColumns;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any[], public dialogRef: MatDialogRef<any>) {}
}
