import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { WorksheetModel } from "../models/worksheet.model";
import { Dispatch } from "./../../despachos/shared/interfaces/dispatch.interface";
import { ExcelService } from "./excel.service";
import { HeaderRetirement } from "./../constants/header-retirement.constant";
import { Report } from "../interfaces/report.interface";
import { DialogTableRetirementComponent } from "../preview/retirement/dialog-table.component";
@Injectable({
  providedIn: "root",
})
export class ExcelRetirementService extends ExcelService implements Report<Dispatch[]> {
  constructor(private dialog: MatDialog) {
    super("Plantilla de retiro");
  }

  generate(_dispatches: Dispatch[]) {
    super.generateOnePage(new WorksheetModel<any>(this.name, HeaderRetirement, this.parsear(_dispatches)));
  }

  preview(dispatches: Dispatch[]): void {
    this.dialog.open(DialogTableRetirementComponent, {
      width: "90vw",
      data: this.parsear(dispatches),
    });
  }

  private parsear(dispatches: Dispatch[]) {
    return dispatches.map((dispatch) => ({
      fieldEmpty: "",
      numberOfPackages: dispatch.products.length,
      storeName: dispatch.user.storeName?.toUpperCase(),
      name: dispatch.user.name?.toUpperCase(),
      phone: dispatch.user.phone?.toUpperCase(),
      withdrawalAddress: dispatch.user.withdrawalAddress?.toUpperCase(),
      address: dispatch.user.address?.toUpperCase(),
      commune: dispatch.user.commune?.name?.toUpperCase(),
      creationTime: dispatch.creationTime,
    }));
  }
}
