import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Zone } from "./../interfaces/zone.interface";

@Injectable({
  providedIn: "root",
})
export class ZoneService {
  private API = environment.API + "zones/";

  constructor(private http: HttpClient) {}

  create(zone: Zone) {
    return this.http.post(this.API, zone);
  }

  getAll() {
    return this.http.get<Zone[]>(this.API);
  }

  delete(zone: Zone): Observable<string> {
    return this.http.delete(this.API + zone._id).pipe(pluck("message"));
  }

  update(zone: Zone): Observable<string> {
    return this.http.put(this.API, zone).pipe(pluck("message"));
  }
}
