import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreviewUserDeliveryColumns } from "../../constants/preview-user-delivery-columns.constant";

@Component({
  selector: "app-dialog-table",
  templateUrl: "./dialog-user-table.component.html",
  styleUrls: ["../../../shared/css/modal.css", "./dialog-user-table.component.css"],
})
export class DialogUserTableDeliveryComponent {
  columns = PreviewUserDeliveryColumns;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any[], public dialogRef: MatDialogRef<any>) {}
}
