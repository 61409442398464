// @ts-ignore
import { Column } from "exceljs/dist/exceljs.min.js";
import { style } from "./styles.constant";

export const HeaderConsolidated: Column[] = [
  { header: "CANTIDAD DE BULTOS", key: "numberOfPackages", width: 30, style },
  { header: "FECHA DEL ENVÍO", key: "retirementDate", width: 30, style },
  { header: "NOMBRE DE LA PYME", key: "storeName", width: 30, style },
  { header: "Total neto", key: "amount", width: 45, style },
  { header: "IVA", key: "iva", width: 45, style },
  { header: "Total bruto", key: "amountWithIVA", width: 35, style },
];
