import { User } from "src/app/usuario/shared/interfaces/user.interface";
import { Dispatch } from "../interfaces/dispatch.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DispatchesService {
  private API = environment.API + "dispatches/";
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Dispatch[]>(this.API);
  }
  getOne(_id: string) {
    return this.http.get<Dispatch>(this.API + _id);
  }

  getAllActive() {
    return this.http.get<Dispatch[]>(this.API + "all-active");
  }

  getAllPendingToWithdraw() {
    return this.http.get<Dispatch[]>(this.API + "pending-to-withdraw");
  }

  getAllScheduledByDate(start: Date, end: Date, users: User[] = []) {
    return this.http.get<Dispatch[]>(this.API + "date/scheduled/" + users.map((user) => user._id), {
      params: { start: String(start), end: String(end) },
    });
  }

  rescheduleRetirementDate(dispatch: Dispatch, retirementDate: Date): Observable<string> {
    return this.http.put(this.API + "retirement-date", { _id: dispatch._id, retirementDate }).pipe(pluck("message"));
  }

  editAmount(dispatch: Dispatch, amount: number): Observable<string> {
    return this.http.put(this.API + "amount", { _id: dispatch._id, amount }).pipe(pluck("message"));
  }
}
