import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent implements OnInit {
  isLoading = false;

  constructor(public loaderService: LoaderService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.changeDetectorRef.detectChanges();
    });
  }
}
