// @ts-ignore
import { Column } from "exceljs/dist/exceljs.min.js";
import { style } from "./styles.constant";

export const HeaderRetirement: Column[] = [
  { header: "", key: "fieldEmpty", width: 20, style },
  { header: "BULTOS", key: "numberOfPackages", width: 30, style },
  { header: "NOMBRE DE TU PYME", key: "storeName", width: 40, style },
  { header: "NOMBRE DE QUIÉN ENTREGA", key: "name", width: 45, style },
  { header: "TELÉFONO", key: "phone", width: 35, style },
  { header: "DIRECCIÓN DE RETIRO", key: "withdrawalAddress", width: 75, style },
  { header: "NÚMERO DE DEPTO/CASA O COMENTARIO EN CASO DE HABER", key: "address", width: 65, style },
  { header: "COMUNA", key: "commune", width: 65, style },
  { header: "HORA DE AGENDAMIENTO DEL RETIRO", key: "creationTime", width: 65, style },
];
